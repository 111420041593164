import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Lightbox } from 'react-modal-image'

const Gallery = ({directory, includeFilename = false}) => {
  const [imageIndex, setImageIndex] = useState(undefined)
  const data = useStaticQuery(graphql`
  {
    allFile(filter: {extension: {in: ["png", "jpg", "jpeg"]}},  sort: {fields: name, order: ASC}) {
      nodes {
        id
        name
        relativeDirectory
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
  `)
  const images = data.allFile.nodes.filter(n => n.relativeDirectory === directory || '')
  return (
    <div>
      <div className='grid grid-cols-3 gap-1 lg:gap-4 py-8 mx-[-32px] lg:mx-0'>
        {images.map((image, index) => (
          <div className='w-full h-full aspect-square' onClick={() => setImageIndex(index)}>
            <GatsbyImage
              image={getImage(image.childImageSharp)} alt={image.name}
              className="aspect-square" />
            { includeFilename ? <h2 className="md:text-xl lg:text-3xl bg-black text-white md:px-2 font-medium w-full">{image.name}</h2> : null}
          </div>
        ))}
      </div>
      {imageIndex !== undefined && (
        <Lightbox
          hideDownload={true}
          large={images[imageIndex].publicURL}
          onClose={() => setImageIndex(undefined)}
          />
      )}
    </div>
  )
}

export default Gallery
